import Glide from "@glidejs/glide"
require("@glidejs/glide/dist/css/glide.core.min.css")
require("@glidejs/glide/dist/css/glide.theme.min.css")

document.addEventListener("turbolinks:load", () => {
  var carousels = document.getElementsByClassName('glide')
  
  if (carousels.length > 0) {
    var glide = new Glide('.glide', {
      type: 'carousel',
      perView: 2,
      focusAt: 'center',
      breakpoints: {
        800: {
          perView: 2
        },
        480: {
          perView: 1
        }
      }
    })

    glide.mount()  
  }
})