import anime from 'animejs/lib/anime.es.js'

document.addEventListener("turbolinks:load", () => {
  // var w = window.innerWidth
  // var leftGap = w*0.2
  //
  // anime({
  //   targets: '.callban',
  //   translateX: leftGap*2,
  //   duration: 1200
  // })
})